<template>
	<!-- 充值 -->
	<div class="container">
		<template v-if="isMobile==0">
			<div class="header">
				<router-link class="btn" to="/renew">{{$t('aside.renew')}}</router-link>
				<i class="iconfont2 icon-right_arrow"></i>
				<span>{{$t('common.recharge')}}</span>
			</div>
			
			<div class="content">
				<div class="recharge_box">
					<div class="row_top">
						<div class="row_left">
							<div class="recharge_form">
								<div class="caption"><span class="icon"><img src="@/assets/images/icon-text.png" /></span> USDT</div>
								<div class="bd">
									<div class="item rechargeType">
										<span class="title">{{$t('renew.rechargeType')}}</span>
										<div class="text">
											<el-radio-group v-model="radioValue">
												<template
												v-for="(item, index) in rechargeOptions">
													<el-radio-button
													:key="index"
													:label="index"
													v-if="item.name!='light_wallet'"
													>{{ item.name.toUpperCase() }}</el-radio-button>
												</template>
											</el-radio-group>
										</div>
									</div>
									<!-- 充值地址 -->
									<div class="item">
										<span class="title">{{$t('renew.rechargeAddress')}}</span>
										<div class="text" v-if="rechargeOptions.length>0">
											<input readonly v-model="rechargeOptions[radioValue].url" type="text" class="inp" :placeholder="$t('renew.rechargeAddress')" />
											<div class="g_btn btn copyBtn" @click="handleCopy">{{$t('renew.copyAddress')}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div class="row_right">
							<div class="recharge_tips">
								<div class="text">
									<p>{{$t('renew.tip')}}</p>
									<br>
									<p v-if="rechargeOptions.length>0">
										{{$t('renew.rechargeTip1',{type:rechargeOptions[radioValue].name.toUpperCase()})}}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="row_bottom">
						<div class="recharge_record">
							<div class="head">
								<i class="iconfont icon-record"></i>
								{{$t('renew.renewRecord')}}
								<span>{{$t('renew.only10')}}</span>
							</div>
							<div class="list">
								<div class="hd">
									<div class="addr">{{$t('renew.renewAddress')}}</div>
									<div class="price">{{$t('renew.renewAmount')}}</div>
									<div class="time">{{$t('renew.renewTime')}}</div>
									<div class="source">{{$t('renew.renewSource')}}</div>
									<div class="state">{{$t('renew.renewState')}}</div>
									<div class="time">{{$t('renew.endTime')}}</div>
									<div class="jump">{{$t('renew.blockQuery')}}</div>
								</div>
								<div class="bd">
									<div class="bd_box">
										<template v-if="rechargeList.length>0">
											<div class="item" v-for="(item,index) in rechargeList" :key="index">
												<div class="addr" :class="{copyPlaceholder:item.address=='light-wallet'||!item.address}">
													<template v-if="item.address">
														<template v-if="item.address!='light-wallet'">
															<el-tooltip effect="dark" :content="$t('subManage.copyAddress')" placement="top" :enterable="false">
																<i class="iconfont2 icon-jilu1" @click="handleCopy2(item.address=='light-wallet'?'Light-wallet':item.address)"></i>
															</el-tooltip>
														</template>
														<el-tooltip effect="dark" :content="item.address" placement="top" :enterable="false">
															<span>{{addressFormat(item.address=='light-wallet'?'Light-wallet':item.address)}}</span>
														</el-tooltip>
													</template>
													<template v-else>
														<span>Light-admin</span>
													</template>
												</div>
												<div class="price">{{item.num}} USDT</div>
												<div class="time">{{item.time}}</div>
												<div class="source">
													{{
                                						!item.address?'Light-admin':
														item.trade_type==0?'/':
														item.trade_type==1?'BEP20':
														item.trade_type==2?'TRC20':
														item.trade_type==3?'Light-wallet':item.trade_type
													}}
												</div>
												<div class="state">
													{{item.state?$t('state')[item.state]:item.state}}
												</div>
												<div class="time">{{item.time_pre}}</div>
												<div class="jump" @click="jump(item.tx,item.trade_type)">
													<i class="iconfont2 icon-tiaozhuan"></i>
												</div>
											</div>
										</template>
										<el-empty v-else :image="emptyImg" :image-size="120" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else-if="isMobile==1">
			<div class="content_phone">
				<div class="recharge_box">
					<div class="recharge_form">
						<div class="caption"><span class="icon"><img src="@/assets/images/icon-text.png" /></span> USDT</div>
						<div class="bd">
							<div class="item rechargeType">
								<div class="title">{{$t('renew.rechargeType')}}</div>
								<div class="text">
									<el-radio-group v-model="radioValue">
										<template
										v-for="(item, index) in rechargeOptions">
											<el-radio-button
											:key="index"
											:label="index"
											v-if="item.name!='light_wallet'"
											>{{ item.name.toUpperCase() }}</el-radio-button>
										</template>
									</el-radio-group>
								</div>
							</div>
							<!-- 充值地址 -->
							<div class="item">
								<div class="title">{{$t('renew.rechargeAddress')}}</div>
								<div class="text" v-if="rechargeOptions.length>0">
									<input readonly v-model="rechargeOptions[radioValue].url" type="text" class="inp" :placeholder="$t('renew.rechargeAddress')" />
									<div class="g_btn btn copyBtn" @click="handleCopy">{{$t('common.copy')}}</div>
								</div>
							</div>
						</div>
					</div>
				
					<div class="recharge_tips">
						<div class="text">
							<p>{{$t('renew.tip')}}</p>
							<p v-if="rechargeOptions.length>0">
								{{$t('renew.rechargeTip1',{type:rechargeOptions[radioValue].name.toUpperCase()})}}
							</p>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'recharge.styl';
</style>
<script>
	import { Loading } from 'element-ui';
	import Clipboard from 'clipboard';
	
	import {mapGetters} from "vuex"
	import addressFormat from '@/utils/methApi/addressFormat'
	// api
	import {getPayRecord,getRechargePath} from '@/api/user'
	export default ({
		name: 'recharge',
		data() {
			return {
				radioValue: 0,
				emptyImg: require('@/assets/images/norecord.png'),
				
				// 此处数据仅用于测试,请自行设置数据名称
				rechargeList: [],
				rechargeOptions: [
					{
						name: 'TRC20',
						url: '',
						balance: '0',
					},
					{
						name: 'BEP20',
						url: '',
						balance: '0',
					},
				],
			}
		},
		computed:{
			...mapGetters(['account','isMobile']),
		},
		created(){
			const loading = Loading.service({
				target: '.main',
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'apploading',
			});
			
			if(this.isMobile!=1){
				Promise.all([this.getRechargePath(),this.getPayRecord()]).then(()=>{
					loading.close()
				}).catch(()=>{
					loading.close()
				})
			}else{
				// 充值地址及余额
				this.getRechargePath().then(()=>{
					loading.close()
				}).catch(()=>{
					loading.close()
				})
			}
		},
		methods: {
			// 地址格式化
			addressFormat(address){
				return addressFormat(address)
			},
			// 充值地址及余额
			async getRechargePath(){
				try{
					let res=await getRechargePath({account:this.account})
					// console.log(res)
					var rechargeOptions=[]
					var wallet_list=res.wallet_list
					for(var key in wallet_list){
						var obj={
							name:key,
							url: wallet_list[key].address,
							balance: wallet_list[key].balance,
						}
						rechargeOptions.push(obj)
					}
					this.$set(this,'rechargeOptions',rechargeOptions.reverse())
					// this.rechargeOptions=rechargeOptions
				}catch(err){
					if(err.code==2176){
						let message = (err.error && err.error.message) || err.message || err.msg ||this.$t('tip.unknownErr');
						this.$confirm(message, this.$t('tip.tips'), {
							confirmButtonText: this.$t('common.confirm'),
							center: true,
							showClose: false,
							customClass: 'deleteOk',
							showCancelButton:false,
						}).then(()=>{
							this.$router.push({
								path:'/renew',
							})
						});
					}
				}
			},
			// 获取续费记录-充值
			async getPayRecord(){
				let res=await getPayRecord({account:this.account})
				console.log(res)
				this.rechargeList=res;
			},
			// 复制
			handleCopy() {
				let code = this.rechargeOptions[this.radioValue].url;
				let clipboard = new Clipboard('.copyBtn', {
					text: () => {
						return code
					}
				})
				clipboard.on('success', () => {
					this.$message({
						message: this.$t('tip.copysuccess'),
						type: 'success',
						center: true,
						customClass: 'shotMsg',
					});
					clipboard.destroy();
				})
				clipboard.on('error', () => {
					this.$message({
						message: this.$t('tip.copyerr'),
						type: 'error',
						center: true,
						customClass: 'shotMsg',
					});
					clipboard.destroy();
				})
			},
			handleCopy2(code) {
				let clipboard = new Clipboard('.copyBtn', {
					text: () => {
						return code
					}
				})
				clipboard.on('success', () => {
					this.$message({
						message: this.$t('tip.copysuccess'),
						type: 'success',
						center: true,
						customClass: 'shotMsg',
					});
					clipboard.destroy();
				})
				clipboard.on('error', () => {
					this.$message({
						message: this.$t('tip.copyerr'),
						type: 'error',
						center: true,
						customClass: 'shotMsg',
					});
					clipboard.destroy();
				})
			},
			// 区块查询
			jump(path,type){
				if(type==3){
					// 如果是闪电钱包 提示不支持查看
					this.$message({
						message: this.$t('tip.notSupported'),
						type: 'info',
						center: true,
						customClass: 'shotMsg',
					});
				}else if(!!path){
					// window.location.href=path
					window.open(path)
				}else{
					this.$message({
						message: this.$t('tip.txSeachErr'),
						type: 'info',
						center: true,
						customClass: 'shotMsg',
					});
				}
			},
		}
	})
</script>
